import React from 'react';
import CommonSection from "../CommonSection/CommonSection";
import Quote from "../Home/Quote/Quote";

const Contact = () => {
    return (
        <React.Fragment>
            <CommonSection
                head="Contact Us"
                text="<p class='text-center'>For new project inquiries, quotes, or to discuss your cloud strategy, please provide some <br />
information about your project in the message field. We also welcome employment inquiries and <br />
questions about our services. Please fill out the form and we'll be in touch shortly.</p>"
                class_div="main_contact_div"
            />
            <div>
                <Quote />
            </div>

                <iframe title="title" width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53562.32823758403!2d-96.86688094897085!3d32.96036726460776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c214a666c3839%3A0xe6594ab6c798f986!2sAddison%2C%20TX!5e0!3m2!1sen!2sus!4v1646012953224!5m2!1sen!2sus">
                    <a href="https://www.gps.ie/sport-gps/">gps watches</a></iframe>


        </React.Fragment>
    );
};

export default Contact;
