import React from 'react';
import CommonSection from "../CommonSection/CommonSection";
import Cloud from "../../assets/service1.png";
import Cost from "../../assets/service2.png";
import Infra from "../../assets/service3.png";
import Devops from "../../assets/service4.png";
import Automated from "../../assets/service5.png";
import App from "../../assets/service6.png";

import "./Service.css";

const Service = () => {

    const serviceData = [
        {
            id: 1,
            img1: Cloud,
            head1: "Cloud Adoption",
            text1: "Our cloud adoption strategy has helped our customers improve on their scability of legacy based critical applications while drastically reducing cost, risk and security related concerns. Whether you ar ein the cloud or just getting started, we will create a roadmap that aligns with your long-term cloud vision and your business drivers",
            img2: Cost,
            head2: "Cost Optimization",
            text2: "Cloud Cost Optimization, being defined as a process of drastically decreasing your your entire cloud expenditures without diminishing your organization's cloud performance. We are focused on ensuring our clients get the full benefits of the cloud; taking advantage of it's flexibility and scability, thus avoiding over-provisioning or under-provisioning of resources for critical business applications",
        },
        {
            id: 2,
            img1: Infra,
            head1: "Infrastructure Management",
            text1: "We offer services backed with technology tools, that bring about appropriate oversight to cloud usage. In addition to that we offer 24x7 monitoring and incident response to maintain availability and system health check across servers, containers and serverless infrastructure.",
            img2: Devops,
            head2: "Devops Consulting",
            text2: "Our DevOps consulting services propels your journey in streamlining your workflow through automation as we eliminate the insolation and descrepancies between development and operations team.",
        },
        {
            id: 3,
            img1: Automated,
            head1: "Automated Deployment and Testing Pipelines",
            text1: "Organizations today need to be able to scale and adapt faster than ever - Our automated services are vital to achieving these capabilities. Our automation team can render support from strategy through execution of automation that delivers on key business priorities" ,
            img2: App,
            head2: "Application Modernization",
            text2: "Our application modernization services addresses the migration of legacy to new applications or platforms, including the integration of new functionality to provide the latest functions to the business. Our services are pre-integrated and tested with various infrastructure services, application services, and cloud services, making your journey to the cloud more agile, efficient, secure, and predictable.",
        },
    ]



    return (
        <React.Fragment>
            <CommonSection
                head="Our Services"
                text="<p class='text-center'>Our services include but are not limited to</p>"
                class_div="main_service_div"
            />
            <div className='container service_div'>
                <div className='row'>
                    {
                        serviceData.map((data, index) => (
                            <React.Fragment key={index}>
                                <div className='col-md-6'>
                                    <div className='service_img_div'>
                                        <img src={data.img1} alt={data.head1} />
                                    </div>
                                </div>
                                <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                    <div className='service_text_div'>
                                        <h1>
                                            {data.head1}
                                        </h1>
                                        <p>
                                            {data.text1}
                                        </p>
                                    </div>

                                </div>
                                {/* ------------------------------ */}
                                <div className='col-md-6 d-flex justify-content-center align-items-center mt-4'>
                                    <div className='service_text_div'>
                                        <h1>
                                            {data.head2}
                                        </h1>
                                        <p>
                                            {data.text2}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-5 mb-5'>
                                    <div className='service_img_div'>
                                        <img src={data.img2} alt={data.head2} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))
                    }

                    <div className='col-md-6'>
                        <div className='service_img_div'>
                            <img src={Cloud} alt={'cloud'} />
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='service_text_div'>
                            <h1>
                                Cloud Training
                            </h1>
                            <p>
                            We offer services that help organizations, professionals and students get the best out of Cutting-edge technology in cloud computing; specifically AWS and GCP. What is "The Cloud", in this context? A cloud is simply a centralised technology platform which provides specific IT Services to a selected range of users, offering the ability to login from anywhere, ideally from any device and over any connection, including the Internet.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Service;
