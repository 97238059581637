import React from 'react';
import CommonSection from "../CommonSection/CommonSection";
import AboutUs from "../../assets/aboutUs.png";
import ConsultingImg from "../../assets/about2-img.png"
import {useNavigate} from "react-router-dom";


const About = () => {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <CommonSection
                head="About Us"
                text="<p class='text-center'><br/> We are open to the unique needs of every company and implemnting a solution that works</p>"
                class_div="main_about_div"
            />

            <div className="first-section">
                <div className='container first_section_div'>
                    <div className='row'>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div className='first_section_img_div'>
                                <img src={AboutUs} alt='about-img' />

                                {/* <p className="about-div-p mt-2">Security</p>
                                <p>Data breaches can be avoided by following best
                                    practices in security and applying discipline
                                    in development practices. We'll lay a solid
                                    foundation for your team to build on.
                                </p>

                                <p className="about-div-p">Process Automation</p>
                                <p> Leveraging the cloud to automate processes like
                                    software testing and deployment is a core factor
                                    to the decision to deploy in AWS cloud. We're
                                    expert devops consultants experienced in transformation
                                    in this area.
                                </p> */}

                            </div>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div className='first_section_text_div'>
                                <p>About Us</p>
                                <h1>About <span> RT Cloud </span> Solutions </h1>
                                <p>RT Cloud Solutions was founded to make reliable, efficient, secure, and well-architected cloud infrastructure accessible to all companies, organizations and teams. We enable you to leverage the transformative power of the cloud to simplify your development and operations processes, and gain new insight into your customers. </p>
                                <p>We are a a solution oriented, customer-centric service provider based in DFW metroplex. We listen, analyze & draw from our experts extensive technical knowledge to deliver tailor made solutions to tackle customer needs.</p>

                                <p>We continually strive to be what the customer hopes we would – Reliable, consistent & trustworthy stewards of the information technology architecture that holds their business together.</p>
                                <p>Our solutions offer a broad range of IT services, particularly tending to small medium size businesses in E-commerce, retail, business and financial services. Customers look to us to manage & secure their data infrastructure, facilitate migration to cloud services and get the best out of their data analytics by architecting custom made simple, effective and affordable strategy.
                                </p>

                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="first-section">
                <div className='container first_section_div'>
                    <div className='row'>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div className='consulting_div'>
                                <h1>Cloud <span> Consulting </span></h1>
                                <p>
                                Because legacy architectures can no longer cope with the explosive growth of devices, applications and data, organizations need an IT environment with the flexibility, scalability and performance to meet these demands.
                                </p>
                                <br />
                                <p>
                                Cloud computing has long been the solution to the data and technology challenges companies face, but it's also brought about its own set of challenges. More specifically, challenges regarding the sheer number of cloud solutions on the market, along with ambiguity in their actual impact on any particular business's needs. Cloud consulting services help solve all of that by helping you select the right cloud solutions that integrate smoothly and deliver optimal return on technology investment.
                                </p>
                                <br />
                                <p>
                                Focus on your applications and leave the infrastructure to us. Our AWS managed services help you control overhead and cut risk with us expertly handling change management, patching, security, backup, and maintenance.
                                </p>
                                <br />
                                <p>
                                We've remained at the forefront of this cloud evolution, guiding our clients through adoption of virtualization and converged infrastructure solutions toward the software-defined infrastructure model and all the benefits that come with cloud computing.
                                </p>
                            <button onClick={() => navigate('/contact-us')} className={'read_more_btn float-left mb-4'}>Contact Us</button>
                            </div>
                        </div>

                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div className='first_section_img_div'>
                                <img src={ConsultingImg} alt='about-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    );
};

export default About;
