import React, {useState} from 'react';
import {Container, Navbar, Nav} from "react-bootstrap";
import {NavLink, useNavigate} from "react-router-dom";
import Logo from "../../assets/logo.svg";
import "./Header.css";
import {Modal} from "react-bootstrap";
import axios from 'axios';

const Header = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
        country: '',
        option: 'register'
    })

    const handleInput = (e) => {
        const { name, value } = e.target

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        //after click on submit button data will save in "formData" you can use this...
        console.log(formData)
        await submitRegister(formData)

        setFormData({
            fullName: '',
            phoneNumber: '',
            email: '',
            country: '',
            option: 'register'
        })
    };

    const submitRegister = async (data) => {
        const url = "https://3o4y5o2ki4.execute-api.us-east-1.amazonaws.com/test/register"
        try {
            const response = await axios.post(url, data)
            console.log(response);
            alert("You have successfully Registered for Training");
            handleClose();
        } catch (error) {
            console.log("Error > "+error);
            alert("You have successfully Registered for Training");
            handleClose();
        }
    }

    const modal = (
        <Modal show={show}>
            <div className="expert_form">
                <h2 className='text-center'>Register for AWS Training</h2>
                <h5 onClick={handleClose}>X</h5>
            </div>
            <div className={'container'}>
                <form onSubmit={sendEmail}>
                <div className={'row'}>
                    <div className={'col-md-12 my-3'}>
                        <input placeholder='Name'
                               name={'fullName'}
                               type={'text'}
                               value={formData.fullName}
                               onChange={handleInput}
                               className='input_div w-100' />
                    </div>

                    <div className={'col-md-12 my-3'}>
                        <input placeholder='Phone Number'
                               name={'phoneNumber'}
                               type={'number'}
                               value={formData.phoneNumber}
                               onChange={handleInput}
                               className='input_div w-100' />
                    </div>

                    <div className={'col-md-12 my-3'}>
                        <input placeholder='Email'
                               name={'email'}
                               type={'email'}
                               value={formData.email}
                               onChange={handleInput}
                               className='input_email_div w-100 mt-0' />
                    </div>

                    <div className={'col-md-12 my-3'}>
                            <input placeholder='Country'
                                    name={'country'}
                                    type={'text'}
                                    value={formData.country}
                                    onChange={handleInput}
                                    className='input_div w-100' />
                    </div>

                    <div className={'col-md-12'}>
                        <button type={'submit'} className={'contact_btn w-100'}>Submit</button>
                    </div>
                </div>
                </form>
            </div>
        </Modal>
    )

    return (
        <React.Fragment>
            {modal}
            <Navbar expand="lg" className="Navbar-header">
                <Container>
                <img onClick={() => navigate('/')} src={Logo} alt={'logo'}/>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <div className="navbar-nav">
                            <NavLink className="nav_link"  to={'/'}>Home </NavLink>
                            <NavLink className="nav_link"  to={'/about-us'}>About Us</NavLink>
                            <NavLink className="nav_link"  to={'/service'}>Services</NavLink>
                            <NavLink className="nav_link" to={'/contact-us'}>Contact us</NavLink>
                        </div>
                    </Nav>
                    {/* <button onClick={() => navigate('/contact-us')} className={'get_started_btn'}>Get Started</button><br /><br /> */}
                    
                    <button onClick={handleShow} className={'register_btn'}>Register</button>
                    
            
                </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
};
export default Header;