import React from 'react';
import {AiFillStar} from "react-icons/ai";
import lady from "../../../assets/lady.png";
import Men1 from "../../../assets/men1.png";
import Men2 from "../../../assets/men2.png";
import "./Testimonials.css";


const Testimonials = () => {

    const testimonialsData = [
        {
            id: 1,
            className: "testimonials_div",
            text: "RT Cloud Solutions helped us achieve substantial cost savings by working with us in migrating about 5 apps previously hosted on approximately 40 servers onto AWS. The process was smooth and without hiccups, we now have a flexible and scalable system that is highly cost optimized",
            personImg:  lady,
            personName: "Jeni B.",
            position: "JC20 Tech",
            classDiv: "testimonials_div"
        },
        {
            id: 2,
            className: "testimonials_div",
            text: "RT Cloud Solutions has helped us reduce cost by 15% by implementing effective cost optimization strategies. With guidance from our RT Cloud Solutions, we were able to right-size large portions of our infrastructure as well as leverage Reserved Instances to achieve more cost savings over time.",
            personImg:  Men1,
            personName: "Roberto",
            position: "Universe Consulting",
            classDiv: "testimonials2_div"
        },
        {
            id: 3,
            className: "testimonials_div",
            text: "RT Cloud Solutions helped us migrate from an on premises data center to the AWS Cloud. This involved moving our database system off Oracle to AWS Aurora PostgreSQL, dramatically improving performance and reducing costs by 44 percent. ",
            personImg:  Men2,
            personName: "Richard",
            position: "TrustRide",
            classDiv: "testimonials3_div"
        },
    ]


    return (
        <React.Fragment>
            <h1 className='testimonial_h1'>Testimonials</h1>
            <div className="container">
                <div className="row">
                    {
                        testimonialsData.map((data, index) => {
                            return(
                                <div key={index} className="col-md-4 mb-4">
                                    <div className={data.classDiv}>
                                        <div>
                                            <p className="text-center">
                                                "{data.text}"
                                            </p>
                                            <div className="w-100 text-center">
                                                {
                                                    Array(...Array(5)).map( () => (
                                                        <AiFillStar className="star_icon"/>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='testimonials_person_div'>
                                        <div className="testimonials_img">
                                            <img src={data.personImg} alt="lady-img"  />
                                        </div>
                                        <div>
                                            <h5>{data.personName}</h5>
                                            <p className="text-center"> {data.position} </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default Testimonials;
